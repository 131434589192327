<script>
/**
 * Marques Component
 */
export default {
  props: ["countByMarque"],
  created() {},
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
        legend: {
          position: "bottom",
        },
        yaxis: {
          title: {
            text: "Marques",
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(1);
            },
          },
          max: 100,
        },
        colors: ["#1A42F4"],
        stroke: {
          width: 5,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
            enabled: true,
            style: {
              colors: ["#1A42F4"],
            },
            formatter: function (val) {
              return val.toFixed(1)+" %";
            },
            offsetY: -20,
          },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " %";
            },
          },
        },
      },
    };
  },
  watch: {
    countByMarque(newVal) {
      let data = [...newVal];
      data.sort((a, b) => {
        return a.percentage < b.percentage ? -1 : 1;
      });
      //let ncObj = null;
      let src = [];
      data.forEach((obj) => {
        if (obj.marque != "NC") {
          src.push({ x: obj.marque, y: obj.percentage.toFixed(1) });
        } /* else {
          ncObj = obj;
        } */
      });
      this.series = [
        {
          name: "pourcentage alerts",
          data: src,
        },
      ];
      /* if (ncObj) {
      } */
    },
  },
};
</script>

<template>
  <div class="card" style="min-height: 500px">
    <div class="card-body">
      <h4 class="card-title mb-4">Marques Equipements</h4>
      <div class="row mt-5" v-if="series.length < 1">
        <div class="col-12 text-center">
          {{ $t("charts.noData.text") }}
        </div>
      </div>

      <div class="row" v-if="series.length > 0">
        <div class="col-12 pb-0 pt-0">
          <apexchart
            class="apex-charts"
            height="370"
            dir="ltr"
            :series="series"
            :options="chartOptions"
            type="bar"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>