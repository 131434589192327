<script>
/**
 * Progress Corr-Rég component
 */
export default {
  props: ["countByTypeequip"],
  created() {},
  data() {
    return {
      source1: {
        series: [69, 31],
        chartOptions: {
          chart: {
            type: "donut",
          },
          legend: {
            position: "bottom",
          },
          labels: ["Réalisées", "Attente"],
          colors: ["#787878", "#E8E8E8"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      source2: {
        series: [
          /* 23, 30, 47 */
        ],
        chartOptions: {
          chart: {
            type: "pie",
          },
          legend: {
            position: "bottom",
          },
          labels: [
            /*"Chambres froide",
            "Armoires frigorifiques",
            "Réfrigerateurs",*/
          ],
          colors: ["#787878", "#F42020", "#F7A929"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    countByTypeequip(newVal) {
      newVal.forEach((obj) => {
        let typeString;
        switch (obj.typeEquip) {
          case 1:
            typeString = "REFRIGERATEUR";
            break;
          case 2:
            typeString = "CONGELATEUR";
            break;
          case 3:
            typeString = "ARMOIRES FRIGORIFIQUE";
            break;
          case 4:
            typeString = "CHAMBRE FROIDE";
            break;
          case 5:
            typeString = "CAMION";
            break;
        }
        this.source2.chartOptions.labels.push(typeString);
        this.source2.series.push(obj.percentage.toFixed(1)*1);
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div class="card" style="min-height: 550px">
    <div class="card-body">
      <!-- <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">Progress de Corrective</h4>
        </div>
      </div>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="370"
          type="donut"
          dir="ltr"
          :series="source1.series"
          :options="source1.chartOptions"
        ></apexchart>
      </div> -->
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">Equipements status</h4>
        </div>
      </div>
      <div class="row mt-5" v-if="source2.series.length < 1">
        <div class="col-12 text-center">
          {{$t("charts.noData.text")}}
        </div>
      </div>
      <div v-if="source2.series.length > 0">
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          class="apex-charts"
          height="450"  
          type="pie"
          dir="ltr"
          :series="source2.series"
          :options="source2.chartOptions"
        ></apexchart>
      </div>
    </div>
  </div>
</template>