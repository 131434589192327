<script>
/**
 * Progress Corr-Rég component
 */
export default {
  props: ["countByMarqueRegion"],
  created() {},
  data() {
    return {
      source: [],
    };
  },
  watch: {
    countByMarqueRegion(newVal) {
      let data = this.mergedResult(newVal).filter(
        (obj) => obj.region.name != "Eddakhla-Oued Eddahab"
      );
      data.forEach((region) => {
        let dataB = { name: "T > 8 °C", data: [] };
        let dataG = { name: "2 °C < T < 8 °C", data: [] };
        let dataS = { name: "T < 2 °C", data: [] };
        region.marques.forEach((obj) => {
          if (obj.marque && obj.marque != "NC") {
            var cptB = 0;
            var cptS = 0;
            obj.types.forEach((t) => {
              if (t.type == "UP" && t.count > 5) cptB += 1;
              else if (t.type == "DOWN" && t.count > 5) cptS += 1;
            });
            if (cptB > 0 || cptS > 0 || obj.count > 0) {
              dataB.data.push({ x: obj.marque, y: cptB });
              dataG.data.push({ x: obj.marque, y: obj.count - (cptB + cptS) });
              dataS.data.push({ x: obj.marque, y: cptS });
            }
          }
        });
        let res = [dataS, dataG, dataB];
        this.source.push({
          series: res,
          chartOptions: {
            chart: {
              type: "bar",
            },
            title: {
              text: region.region.name,
            },
            legend: {
              position: "bottom",
            },
            xaxis: {
              labels: {
                rotate: -25,
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                },
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " enregistreur(s)";
                },
              },
            },
            colors: ["#1A42F4", "#14FB42", "#F42020"],
            plotOptions: {
              bar: {
                columnWidth: 50,
                dataLabels: {
                  position: "top",
                },
              },
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#1A42F4", "#14FB42", "#F42020"],
              },
              offsetY: -20,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
        });
      });
    },
  },
  mounted() {},
  methods: {
    mergedResult(newval) {
      return newval.r1.map(({ region, marques }) => {
        const match = newval.r2.find(
          ({ region: resRegion }) => resRegion === region._id
        );
        if (match) {
          let data = {
            region,
            marques: marques.map((marque) => {
              const resMatch = match.marques.find(
                ({ marque: resMarque }) => resMarque === marque.marque
              );
              if (resMatch) {
                return {
                  marque: marque.marque,
                  types: marque.types,
                  count: resMatch.count,
                };
              }
              return marque;
            }),
          };
          return data;
        }
        return {
          region,
          marques,
        };
      });
    },
  },
};
</script>

<template>
  <div class="card" style="min-height: 500px">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">
            Nombres d'enregistreurs ayant des alerts par marque et région
          </h4>
        </div>
      </div>
      <div class="row mt-5" v-if="source.length < 1">
        <div class="col-12 text-center">
          {{ $t("charts.noData.text") }}
        </div>
      </div>
      <div class="row">
        <div class="col-6" v-for="(data, index) in source" :key="index">
          <div v-if="data.series.length > 0">
            <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
            <apexchart
              class="apex-charts"
              height="370"
              type="bar"
              dir="ltr"
              :series="data.series"
              :options="data.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>