<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import InterData from "./interventions-data";
import AddIntervention from "./add-intervention";
import Operations from "./operations";
import Average from "./average";
import Marques from "./marques";
import MarquesRegion from "./marques-by-region"
import MarquesStats from "./marques-stats"

import {
  layoutMethods,
  layoutComputed,
  authComputed,
  interventionsComputed,
  interventionsMethods,
  interventionOperationsComputed,
  interventionOperationsMethods,
  alertsComputed,
  alertsMethods,
} from "@/state/helpers";
/**
 * Maintenance component
 */
export default {
  page: {
    title: "Maintenance",
    meta: [{ name: "Maintenance" }],
  },
  components: {
    Layout,
    PageHeader,
    InterData,
    AddIntervention,
    Operations,
    Average,
    Marques,
    MarquesRegion,
    MarquesStats
  },
  mounted() {},
  created() {
    this.getAllInterventions({}).then(() => {
      this.getAllInterventionOperations();
      this.getCountAction();
      this.getCountTypeequip();
      this.getCountMarque();
      this.getCountMarqueRegion();
      this.getStatsMarque();
    });
  },
  data() {
    return {
      title: "Interventions",
      interventionsData: [],
      countByAction: [],
      countByTypeequip: [],
      countByMarque: [],
      countByMarqueRegion: [],
      statsByMarque: [],
      interventionOperationsData: [],

      busy: true,
      currentPage: 1,
      perPage: 6,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...interventionsComputed,
    ...interventionOperationsComputed,
    ...alertsComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: "Interventions",
          active: true,
        },
      ];
    },
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...interventionsMethods,
    ...interventionOperationsMethods,
    ...alertsMethods,

    openAdd() {
      this.showModalAdd();
    },
    showModalAdd() {
      this.$bvModal.show("add-intervention-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-intervention-modal");
    },
    addInterv(intervention) {
      this.addIntervention({ ...intervention })
        .then(() => {
          this.makeToast(
            "Add Intervention",
            "Intervention has been added",
            "success"
          );
          this.hideModalAdd();
        })
        .catch((error) => {
          this.makeToast("Add Intervention", error, "danger");
        });
    },
    async getAllInterventions({ perPage, page }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: "createdAt:desc",
        type: "CORRECTIVE",
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getInterventionsBy(paramsFilter).then((interventions) => {
        this.busy = false;
        return interventions;
      });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.getAllInterventions({});
    },
    getCountAction() {
      this.getCountByActionBy({});
    },
    getCountTypeequip() {
      this.getCountByTypeequipBy({});
    },
    getCountMarque() {
      this.getCountByMarqueBy({});
    },
    getCountMarqueRegion() {
      this.getCountByMarqueRegionBy({});
    },
    getStatsMarque() {
      this.getStatsByMarqueBy({});
    },
    getAllInterventionOperations() {
      this.getInterventionOperationsBy({
        populate: "interventionActions",
        limit: 100,
      });
    },
  },
  watch: {
    getInterventions(newVal) {
      if (newVal.results) this.interventionsData = newVal.results;
    },
    getInterventionOperations(newVal) {
      if (newVal.results) this.interventionOperationsData = newVal.results;
    },
    getCountByAction(newVal) {
      this.countByAction = newVal;
    },
    getCountByTypeequip(newVal) {
      this.countByTypeequip = newVal;
    },
    getCountByMarque(newVal) {
      this.countByMarque = newVal;
    },
    getCountByMarqueRegion(newVal){
      this.countByMarqueRegion = newVal;
    },
    getStatsByMarque(newVal){
      this.statsByMarque = newVal;
    }
  },
  beforeDestroy() {
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>
      <div
        class="row"
        v-if="['SYSADMIN', 'ADMIN', 'MANAGER', 'OPERATOR'].includes(userRole)"
      >
        <div class="col-12 text-right">
          <b-button type="button" class="mr-2" variant="info" @click="openAdd"
            >{{ $t("buttons.addIntervention.text") }}
          </b-button>
          <AddIntervention
            @addIntervention="addInterv"
            v-bind:interventionOperationsData="interventionOperationsData"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InterData v-bind:interventionsData="interventionsData" :busy="busy" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            class="dataTables_paginate paging_simple_numbers float-right mb-3"
          >
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="getInterventions.totalResults"
                :per-page="perPage"
                @change="handlePageChange"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <Operations v-bind:countByAction="countByAction" />
      </div>
      <div class="col-6">
        <Average v-bind:countByTypeequip="countByTypeequip" />
      </div>
      <div class="col-12">
        <Marques v-bind:countByMarque="countByMarque" />
      </div>
      <div class="col-12">
        <MarquesStats v-bind:countByMarque="statsByMarque" />
      </div>
      <div class="col-12">
        <MarquesRegion v-bind:countByMarqueRegion="countByMarqueRegion" />
      </div>
    </div>
  </Layout>
</template>