<template>
  <div>
    <b-modal
      id="add-intervention-modal"
      ref="add-intervention-modal"
      size="xl"
      @hidden="cancel"
      :title="$t('modals.addIntervention.text')"
    >
      <!-- Type -->
      <div class="row">
        <div class="col-12 col-lg-12 pl-4">
          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="interventionAdd.type"
              :aria-describedby="ariaDescribedby"
              :options="types"
              value="TYPE"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <!-- Serial & technicien -->
      <div class="row">
        <div class="col-6">
          <label for="serial">{{ $t("modals.serial.text") }}</label>
          <b-form-input
            id="serial"
            v-model="interventionAdd.serial"
            class="mb-1"
            :placeholder="$t('modals.serial.text')"
            :state="err.serial ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.serial }}
          </b-form-invalid-feedback>
        </div>
        <div class="col-6">
          <label for="techName">{{ $t("modals.techName.text") }}</label>
          <b-form-input
            id="techName"
            v-model="interventionAdd.techName"
            class="mb-1"
            :placeholder="$t('modals.techName.text')"
            :state="err.techName ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.techName }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Operations -->
      <div class="row" v-if="templateType == 1">
        <div class="col-12 col-lg-12">
          <div
            class="row"
            v-for="(item, index) in operationsPreventive"
            :key="index"
          >
            <div class="col-8 text-center">
              <p>
                {{ item.name }}
              </p>
            </div>
            <div class="col-4 text-center">
              <b-form-checkbox
                v-model="item.state"
                name="check-operation"
                switch
              >
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="templateType == 2">
        <div class="col-12 col-lg-12">
          <div class="row" v-for="(item, index) in operations" :key="index">
            <div class="col-6 text-center">
              <p>
                {{ item.name }}
              </p>
            </div>
            <div class="col-6 text-center">
              <b-radio-group v-slot="{ ariaDescribedby }">
                <div
                  v-for="(action, index) in item.interventionActions"
                  :key="index"
                  style="display: flex; flex-direction: row"
                >
                  <b-form-radio
                    v-model="item.action"
                    :aria-describedby="ariaDescribedby"
                    :name="action.name"
                    :value="action.id"
                    class="w-50 ml-3"
                    >{{ action.name }}</b-form-radio
                  >
                  <!-- <b-form-checkbox v-model="action.state" name="check-action">
                </b-form-checkbox>
                <p style="min-width: 50%">{{ action.name }}</p> -->
                  <b-form-input
                    id="details"
                    v-model="item.details"
                    class="w-50 ml-3"
                    placeholder="..."
                  >
                  </b-form-input>
                </div>
              </b-radio-group>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{ $t("buttons.add.text") }}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()">
              {{ $t("buttons.cancel.text") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["interventionOperationsData"],
  created() {},
  data() {
    return {
      types: [
        {
          text: "PREVENTIVE",
          value: "PREVENTIVE",
        },
        {
          text: "CORRECTIVE",
          value: "CORRECTIVE",
        },
      ],
      operationsPreventive: [],
      operations: [],
      templateType: 2,
      interventionAdd: {
        type: "CORRECTIVE",
        operations: [],
        serial: null,
        techName: null,
      },
      err: {
        serial: null,
        techName: null,
      },
    };
  },
  watch: {
    "interventionAdd.type"(newVal) {
      this.interventionAdd.operations = [];
      if (newVal == "CORRECTIVE") this.templateType = 2;
      else this.templateType = 1;
    },
    interventionOperationsData(newVal) {
      this.setData(newVal);
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    setData(data){
      this.operationsPreventive = [];
      this.operations = [];
      data.forEach((obj) => {
        this.operations.push({
          id: obj.id,
          name: obj.name,
          action: null,
          details: null,
          interventionActions: obj.interventionActions
        });
        this.operationsPreventive.push({
          id: obj.id,
          name: obj.name,
          state: true,
        });
      });
    },
    clear() {
      this.interventionAdd = {
        type: "CORRECTIVE",
        operations: [],
        serial: null,
        techName: null,
      };
      this.setData(this.interventionOperationsData);
    },
    add() {
      this.interventionAdd.operations = [];
      if (this.templateType == 1) {
        this.operationsPreventive.forEach((obj) => {
          this.interventionAdd.operations.push({
            operation: obj.id,
            conforme: obj.state,
          });
        });
      } else {
        this.operations.forEach((obj) => {
          if (obj.action) {
            let operation = {
              operation: obj.id,
              action: obj.action,
            }
            if(obj.details) operation.details = obj.details;
            this.interventionAdd.operations.push(operation);
          }
        });
      }
      this.$emit("addIntervention", this.interventionAdd);
    },
  },
};
</script>