<script>
/**
 * Sources Component
 */
export default {
  props: ["countByAction"],
  created() {
  },
  data() {
    return {
      chartData: [],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "20%",
            colors: {
              backgroundBarColors: ["#E8E8E8"],
            },
          },
        },
        colors: ["#F42020"],
        stroke: {
          width: 0,
        },
        tooltip: {
            enabled: false
        }
      },
    };
  },
  watch: {
    countByAction(newVal) {
      let data = [...newVal]
      data.sort((a, b)=>{
        return a.percentage < b.percentage ? -1 : 1
      })
      let chartAllData = [];
      data.forEach((obj) => {
        chartAllData.push({
          series: [
            {
              data: [obj.percentage.toFixed(1)],
            },
          ],
          options: {
            ...this.chartOptions,
            title: {
              floating: true,
              offsetX: -10,
              offsetY: 3,
              text: obj.action,
            },
            subtitle: {
              floating: true,
              align: "right",
              offsetY: 0,
              text: obj.percentage.toFixed(1) + "%",
              style: {
                fontWeight: "bold",
                fontSize: "14px",
              },
            },
            yaxis: {
              max: 100,
            },
          },
        });
      });
      this.chartData = chartAllData;
    },
  },
};
</script>

<template>
  <div class="card" style="min-height: 550px">
    <div class="card-body">
      <h4 class="card-title mb-4">Opérations curatives</h4>
      <div class="row mt-5" v-if="chartData.length < 1">
        <div class="col-12 text-center">
          {{$t("charts.noData.text")}}
        </div>
      </div>
      <div class="row" v-if="chartData.length > 0">
        <div class="col-12 pb-0 pt-0" v-for="(item, index) in chartData" :key="index">
          <apexchart
            class="apex-charts"
            height="70"
            dir="ltr"
            :series="item.series"
            :options="item.options"
            type="bar"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>