<script>
/**
 * Progress Corr-Rég component
 */
export default {
  props: ["countByMarque"],
  created() {},
  data() {
    return {
      series1: [],
      series2: [],
      chartOptions: {},
    };
  },
  watch: {
    countByMarque(newVal) {
      let dataB = { name: "T > 8 °C", data: [] };
      let dataG = { name: "2°C < T < 8 °C", data: [] };
      let dataS = { name: "T < 2 °C", data: [] };
      /* let dataB2 = { name: "T > 8 °C", data: [] };
      let dataG2 = { name: "2°C < T < 8 °C", data: [] };
      let dataS2 = { name: "T < 2 °C", data: [] }; */
      newVal.forEach((marque) => {
        if (marque.marque && marque.marque != "NC") {
          var cptB = 0;
          var cptS = 0;
          var cptG = 0;
          marque.alerts.forEach((obj) => {
            if (obj.type == "UP" && obj.count > 5) cptB += 1;
            else if (obj.type == "DOWN" && obj.count > 5) cptS += 1;
            cptG = marque.totalDevices - (cptB + cptS);
          });
          if (cptB > 0 || cptS > 0 || cptG > 0) {
            dataB.data.push({ x: marque.marque, y: cptB });
            dataG.data.push({ x: marque.marque, y: cptG });
            dataS.data.push({ x: marque.marque, y: cptS });
          }
        }
      });
      this.series1 = [
        {
          ...dataS,
          data: dataS.data.slice(0, Math.ceil(dataS.data.length / 2)),
        },
        {
          ...dataG,
          data: dataG.data.slice(0, Math.ceil(dataG.data.length / 2)),
        },
        {
          ...dataB,
          data: dataB.data.slice(0, Math.ceil(dataB.data.length / 2)),
        },
      ];
      this.series2 = [
        {
          ...dataS,
          data: dataS.data.slice(
            dataS.data.length / 2,
            Math.ceil(dataS.data.length)
          ),
        },
        {
          ...dataG,
          data: dataG.data.slice(
            dataG.data.length / 2,
            Math.ceil(dataG.data.length)
          ),
        },
        {
          ...dataB,
          data: dataB.data.slice(
            dataB.data.length / 2,
            Math.ceil(dataB.data.length)
          ),
        },
      ];
      this.chartOptions = {
        chart: {
          type: "line",
        },
        legend: {
          position: "bottom",
        },
        yaxis: {
          title: {
            text: "Marques",
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " enregistreur(s)";
            },
          },
        },
        colors: ["#1A42F4", "#14FB42", "#F42020"],
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
            enabled: true,
            style: {
              colors: ["#1A42F4", "#14FB42", "#F42020"],
            },
            offsetY: -20,
          },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div class="card" style="min-height: 500px">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h4 class="card-title mb-4">
            Nombres d'enregistreurs ayant des alerts par marque
          </h4>
        </div>
      </div>
      <div class="row mt-5" v-if="series1.length < 1">
        <div class="col-12 text-center">
          {{ $t("charts.noData.text") }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="series1.length > 0">
            <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
            <apexchart
              class="apex-charts"
              height="370"
              type="bar"
              dir="ltr"
              :series="series1"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="series2.length > 0">
            <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
            <apexchart
              class="apex-charts"
              height="370"
              type="bar"
              dir="ltr"
              :series="series2"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>